import React from "react"
import styled from "styled-components"
import { ListScroll } from "../utils/animations"

export const Carousel = styled.div`
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: stretch;
  height: 150px;
  position: relative;
`

export const Item = styled.div`
  background: #9b65de;
  border-radius: 5px;
  margin-right: 15px;
  flex: 0 0 110px;

  &:nth-child(4n + 2) {
    background: #14d790;
  }

  &:nth-child(4n + 3) {
    background: #ff1c68;
  }

  &:nth-child(4n + 4) {
    background: #198fe3;
  }
`

export const VerticalCenter = styled.div`
  /*flex: 0 1 450px;
  max-width: 450px;
  display: flex;
  perspective: 500px;
  transform-style: preserve-3d;
overflow:hidden;
    flex: 0 0 100%;
  align-items: center;*/
`

const generateItems = () => {
  const renderedItems = []
  for (let i = 0; i < 100; i++) {
    renderedItems.push(<div className="carousel__item" key={i} />)
  }
  return renderedItems
}

const items = generateItems()

class Slider extends React.Component {
  componentDidMount() {
    ListScroll()
  }

  render() {
    return (
      <VerticalCenter>
        <div className="carousel">
          <div className="carousel-inner">{items}</div>
        </div>
      </VerticalCenter>
    )
  }
}

export default Slider
/*
export default () => (
    <Template code={code} codepen="https://codepen.io/popmotion/pen/Kyewbv?editors=0010">
        <VerticalCenter>
            <Slider />
        </VerticalCenter>
    </Template>
);
*/
